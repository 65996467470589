import * as React from 'react'

import { Grid, Card, Button, Icon, List} from 'tabler-react'

interface Props {
  functionalArea: any
}

class FunctionalAreaTableCard extends React.Component<Props> {
  render () {
    return (
      <div className='container' >
        <h3 className='uppercase'> Find opportunities perfect for you</h3>
        <Card>
       
          <Grid.Row cards deck className='margin-none'>
            {
              this.props.functionalArea.map((functionalArea, index) => {
                return(
                  <Grid.Col key={index} lg={3} xs={12} className='padding-none'>
                    <Card className='home-functional-area'>
                      <Icon name={functionalArea.icon} />
                      <List unstyled>
                        <List.Item> <Button className='padding-none functional-area-title' link RootComponent="a" href={'/jobs?search=true&&keywords=' + functionalArea.name}>{functionalArea.name}</Button></List.Item>
                        {
                          functionalArea.secondaryArea.map((subCategory, index) => {
                            return(
                              <List.Item key={index}>
                                <Button className='padding-none text-left' link RootComponent="a" href={'/jobs?search=true&&keywords=' + subCategory.name}>{subCategory.name}</Button>
                              </List.Item>
                            )
                          })
                        }
                      </List>
                    </Card>
                  </Grid.Col> 
                )
              })
            }
           </Grid.Row>
        </Card>
      </div>
    )
  }
}

export default FunctionalAreaTableCard
